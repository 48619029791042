.chat-circle {
  background-color: #25d366;
  z-index: 2000;
  bottom: 4em;
  left: 3em;
  cursor: pointer;
  position: fixed;
  padding: 0.3rem 1rem;

  &:hover {
    opacity: 0.9;
  }
}
