$widthLogtipo: 250px;
$pe-logotipo: 20px;
// Styling
.slider {
  margin: auto;
  overflow: hidden;
  position: relative;

  &::before,
  &::after {
    content: "";
    height: 100%;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }

  .slide {
    padding-right: $pe-logotipo;
    height: 100px;
    width: $widthLogtipo;
    display: flex;
    position: relative !important;
    img {
      margin: auto 0 !important;
      width: $widthLogtipo - $pe-logotipo;
    }
  }
}

.nav-pills .nav-link {
  background-color: var(--bs-secondary);
  color: #fff !important;
}
