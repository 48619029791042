$primary-wd: #e00000;
$secondary-wd: #ed860c;

.powered-by {
  a {
    font-size: 0.8rem;
    color: #fff !important;
    span {
      font-size: 1.1rem !important;
      font-style: italic;
      color: $secondary-wd;
      &:first-child {
        color: $secondary-wd !important;
        font-size: 0.8rem !important;
      }
      &:first-of-type {
        color: $primary-wd;
      }
    }
    &:hover {
      opacity: 0.7;
    }
  }
}
