$p-container: 3.4em;

.container-page {
  padding: $p-container 0 $p-container 7%;

  img {
    width: 20em;
    margin-bottom: 1em;
  }
}
