$mn-container: -7em;

.container-business {
  background-color: #fff;
  box-shadow: -0.5px 1em 0 rgba(0, 0, 0, 0.25);
  margin: 2em 0 $mn-container 0;
}

.section-2 {
  padding-top: ($mn-container * -1.8);
  padding-bottom: 4em;
}

.container-service {
  background-color: #fff;
  padding: 0;
  position: relative !important;
  box-shadow: 0.5em 0.5em 5px rgba(0, 0, 0, 0.25);
}

.wp-border {
  margin: 0;
  padding-bottom: 0.5em;
  border-width: 1px;
  border-bottom-style: dashed;

  & + span {
    font-size: .7em;
  }
}
