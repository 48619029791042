.wp-border {
  margin: 0;
  padding-bottom: 0.5em;
  border-width: 1px;
  border-bottom-style: dashed;

  & + span {
    font-size: 0.7em;
  }
}
