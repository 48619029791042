.to-top {
  z-index: 2000;
  bottom: 4rem;
  right: 2rem;
  cursor: pointer;
  position: fixed;
  opacity: 0;
  color: #fff;
  padding: 0.6em 1em;
}

.to-top:hover {
  opacity: 1;
}

.show-scrollTop {
  opacity: 0.8;
  transition: all 0.2s ease-in-out;
}
