body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "InterBold";
  src: local("InterBold"),
    url("./fonts/Inter/Inter-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "InterSemiBold";
  src: local("InterSemiBold"),
    url("./fonts/Inter/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "OpenSansRegular";
  src: local("OpenSansRegular"),
    url("./fonts/OpenSans/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
}
